$('.quiz__next--1').click(function(){
    $('.quiz__left').slideUp();
    $('.quiz__left--2').slideDown();
});

$('.quiz__next--2').click(function(){
    $('.quiz__left').slideUp();
    $('.quiz__left--3').slideDown();
});

$('.quiz__next--3').click(function(){
    $('.quiz__left').slideUp();
    $('.quiz__left--4').slideDown();
});

$('.quiz__next--4').click(function(){
    $('.quiz__left').slideUp();
    $('.quiz__left--5').slideDown();
});

function f_acc() {
    $("#accordeon .acc-body").not($(this).next()).slideUp(500).prev().removeClass("active"),
        $(this).next().slideToggle(500).prev().toggleClass("active");
}
$(document).ready(function() {
    $("#accordeon .acc-head").on("click", f_acc)
});

var $page = $('html, body');
$('a[href*="#"]').click(function() {
    $page.animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 1400);
    return false;
});